import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import title_image from '../images/contact_title_image.jpg'
import { Link } from "gatsby"

class ThankYouPage extends React.Component{
  render(){
    return(
      <Layout pageType="thank-you">
      <SEO title="Thank You" />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
        <div className="overlay"></div>
          <div className="container">
              <h1 className="title">Thank you!</h1>
          </div>
        </section>
  
        <section className="section thank-you-page">
            <div className="container">
                  <p>Congratulations, your message was sent. We will try to answer as soon as possible.</p>
                  <div className="button-area">
                    <Link to="/" className="button green">Homepage</Link>
                  </div>
            </div>
        </section>
    </Layout>
  
    )
  }
}

export default ThankYouPage
